body {
	color: rgba(0, 0, 0, 0.75);
	margin: 0;
	padding: 0;
	font-family: 'Varela Round', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
	min-height: 100%;
	position: relative;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h2 {
	font-size: 20px;
}

@media only screen and (min-width: 480px) {
	h2 {
		font-size: 25px;
	}
}
